header {
  height: 100vh;
  padding-top: 7rem;
  overflow: hidden;
}

.header_container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* ********************CTA*************** */
.cta {
    margin-top:2.5rem ;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* ********************HEADER SOCIAL*************** */

.header_socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    left: 0;
    bottom: 3rem;
    position: absolute;
}

.header_socials::after {
    content: "";
    width: 1px;
    height: 2rem;
    background-color: var(--color-primary);

}

/* ******************** ME *************** */

.me {
    background-color:linear-gradient (var(--color-primary),transparent);
    width: 23rem;
    height: 100%;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 1rem;
    /* border-radius: 12rem 12rem 0 0; */
    border-radius: 2rem;
    overflow: hidden;
    /* padding: 1rem 1.5rem 1.5rem 1.5rem;  */
    border: 3px solid var(--color-primary);
}

/* ******************** SCROLL DOWN *************** */

.scroll_down {
    position: absolute;
    right: -2.5rem;
    bottom: 5rem;
    /*transform: rotate(90deg); */
    font-weight: 300;
    font-size: 2rem;
    box-shadow: 2;
}

/* ********* MEDIA QUERIESFOR MEDIUM DEVICES(TABLET DEVICES) */

@media screen and (max-width:1024px) {
 .header {
   height: 80vh;
 }
}

/* ********* MEDIA QUERIESFOR SMALL DEVICES(MOBILE DEVICES) */

@media screen and (max-width:600px) {
    .header {
        height: 100vh;
    }
    .header_socials, .scroll_down {
        display: none;
    }
}