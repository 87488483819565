.container.contact__container {
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12rem;
}

.contact__options {
 display: flex;
 flex-direction: column;
 gap: 1.2rem;
}

.contact__option {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1rem;
    text-align: center;
    border: 1px solid transparent;
}

.contact__option:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
}

.contact__option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact__option a {
   margin-top: 0.5rem;
   display: inline-block;
   font-size: 0.8rem;
}

form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    background: transparent;
    border-radius: 1.3rem;
    resize: none;
    font-size: 1rem;
    border: 4px solid var(--color-bg-variant);
    color: var(--color-white);
    box-shadow: 1rem 0 1rem rgba(0, 0, 0, 2);
}

/* ********* MEDIA QUERIESFOR MEDIUM DEVICES(TABLET DEVICES) */

@media screen and (max-width:1024px) {
  .container.contact__container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

/* ********* MEDIA QUERIESFOR SMALL DEVICES(MOBILE DEVICES) */

@media screen and (max-width:600px) {
.container.contact__container {
    width: var(--container-width-sm);
}
}