.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.3rem;
}

.portfolio__item {
    background: var(--color-bg-variant);
    padding: .4rem;
    height: 25rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__item:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
}

.portfolio__item-image {
    border-radius: 1.5rem;
    height: 60%;
    overflow: hidden;
}

.portfolio__item h3 {
    margin: 1rem 0 1rem;
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
}


/* ********* MEDIA QUERIESFOR MEDIUM DEVICES(TABLET DEVICES) */

@media screen and (max-width:1024px) {
.portfolio__container {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

}

/* ********* MEDIA QUERIESFOR SMALL DEVICES(MOBILE DEVICES) */

@media screen and (max-width:600px) {
.portfolio__container {
    grid-template-columns: 1fr;
    gap: 1rem;
}

}